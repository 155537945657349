import React, {useMemo} from 'react';
import DatePicker from "react-datepicker";
import classNames from "classnames";
import {toast} from "react-toastify";
import useFormValidation from "../../../hooks/useFormValidation";
import {useCreateTimeSheetMutation} from "../../../api/TimeSheetApi";
import {useTranslation} from "react-i18next";
import SelectWithLabel from "../../../components/interactive/SelectWithLabel";
import {useGetTaskSelectItemsQuery} from "../../../api/TaskApi";
import {mapTasksToOptions} from "../../../utils/task-utils";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";

const defaultTimeSheet = {
    id: "0",
    timeSpent: undefined,
    timeSpentDate: new Date(),
    description: undefined,
    taskId: undefined
};

const TimeSheetForm = ({projectId, taskId, handler}) => {
    const {t} = useTranslation();
    const {data} = useGetTaskSelectItemsQuery('', {skip: !!taskId});
    const [createTimeSheet, {isLoading: isCreating}] = useCreateTimeSheetMutation();

    const handleSubmit = async values => {
        try {
            await createTimeSheet(values).unwrap();
            if (handler) handler();
        } catch (error) {
            toast.error(t('time_sheet.form.error.time_sheet_not_created'));
        }
    };

    const validateForm = values => {
        let errors = {};
        if (!values.taskId) {
            errors.taskId = "Veuillez sélectionner une tâche";
        }

        if (!values.timeSpent) {
            errors.timeSpent = "Veuillez ajouter du temps passé";
        }
        return errors;
    };

    const {
        values, errors, handleOnChange, handleOnDateChange, handleOnSelectChange, handleOnSubmit
    } = useFormValidation({...defaultTimeSheet, taskId}, validateForm, handleSubmit);

    const taskOptions = useMemo(() => {
        if (!data) return [];

        let options = [...data];
        if (projectId && data.some(element => element.projectId === projectId)) {
            options = data.filter(element => element.projectId === projectId);
        }

        if (values.projectId && data.some(element => element.projectId === values.projectId)) {
            options = data.filter(element => element.projectId === values.projectId);
        }

        return mapTasksToOptions(options);
    }, [data, projectId, values.projectId]);

    const groupedTasks = groupBy(data, c => c.projectId);

    const projectOptions = keys(groupedTasks).map(group => ({
        value: parseInt(group),
        label: groupedTasks[group][0].projectName
    }));

    return (
        <div className="container-popover form row">
            <div className='col-12 form-heading'>
                <h3>
                    Ajouter une prestation
                </h3>
            </div>
            <div className="col-md-12">
                {(!projectId && !taskId) && <SelectWithLabel label="Projet" options={projectOptions}
                                                             placeholder="Veuillez choisir un projet..."
                                                             values={values} name="projectId" error={errors?.phase}
                                                             onChange={handleOnSelectChange}/>}
                {!taskId && <SelectWithLabel label="Tâche" options={taskOptions}
                                             placeholder="Veuillez choisir une tâche..."
                                             values={values} name="taskId" error={errors?.phase}
                                             isDisabled={!values.projectId && !projectId}
                                             onChange={handleOnSelectChange}/>}

                <label className='label-component'>
                    Date de la prestation
                </label>
                <div className='container-date'>
                    <DatePicker
                        isClearable
                        tabIndex="1"
                        className="input-text"
                        onChange={value => handleOnDateChange(value, "timeSpentDate")}
                        dateFormat="MMMM d, yyyy"
                        locale="fr"
                        placeholderText="Veuillez entrer une date..."
                        selected={values["timeSpentDate"]}/>
                </div>
                <label className='label-component'>
                    Temps passé
                </label>
                <div className={classNames("container-time w-100", {"is-invalid": errors?.timeSpent})}>
                    <input id="timeSpent" name="timeSpent" type="number" value={values["timeSpent"]}
                           onChange={handleOnChange}
                           tabIndex="2"
                           placeholder="Veuillez entrer un temps passé..."
                           className={classNames('input-text', {"is-invalid": errors?.timeSpent})} min={0}
                           step={0.25}/>
                </div>
                {errors?.timeSpent && <span className="text-danger">{errors.timeSpent}</span>}
                <div className="container-text">
                    <label className='label-component'>
                        Description
                    </label>
                    <textarea className='textarea' placeholder='Veuillez choisir une description...'
                              tabIndex="3"
                              onChange={handleOnChange} name="description" value={values.description}/>
                </div>
            </div>
            <div className='col-md-12 btn-group text-center'>
                <button className='btn-ternary' onClick={handleOnSubmit} tabIndex="4"
                        disabled={isCreating}>
                    enregistrer
                </button>
            </div>
        </div>
    );
};

export default TimeSheetForm;